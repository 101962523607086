.pre-reco-container {
    height: 420px;
  max-height: 420px; /* Adjust as needed */
  overflow-y: auto;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box; /* Include padding and border in width */
  padding-left: 40px;
  padding-right: 40px;
}

.pre-reco-container h2 {
  color: #000000;
}

.recommendation-ids {
  list-style-type: none;
  padding: 0;
}

.recommendation-ids li {
  margin-bottom: 8px;
}

.recommendation-ids button {
  /* background: linear-gradient(to right, #fa0366 -40%, #000000 140%); */
  background: #000000;
/* background-clip: text; */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
    font-weight: 500;
    cursor: pointer;
  text-decoration: underline;
  border: none;
  border-radius: 8px;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s;
  width: 300px;
}

.recommendation-ids button:hover {
  /* background: linear-gradient(to right, #fa0366 -40%, #000000 140%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
    font-weight: 500;
    cursor: pointer; */
  background-color: #f8f9fa;
  color: #000000;
  text-decoration: underline;
}

.error-message {
  color: rgb(140, 81, 81);
}

.go-back-button {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  padding: 9px 15px;
  border: #f0f0f0;
  margin-bottom: 10px;
}

.go-back-button:hover {
  background: linear-gradient(to right, #fa0366 -40%, #000000 140%);
  background-clip: text;
  -webkit-background-clip: text;
  /* -webkit-text-fill-color: transparent; */
  font-weight: 700;
  cursor: pointer;
  text-decoration: underline;
}

/* Mobile view adjustments */
@media (max-width: 600px) {
  .pre-reco-container {
    max-height: 420px; /* Maintain max height to allow scrolling */
    overflow-y: auto; /* Enable scrolling for overflow content */
    padding: 10px 20px; /* Adjust padding */
    box-shadow: none; /* Simplify shadow for a cleaner look */
    border-radius: 0; /* Remove border-radius for edge-to-edge fit */
  }

  .pre-reco-container h2 {
    font-size: 1.2rem; /* Adjust heading font size */
  }

  .recommendation-ids {
    max-height: 300px; /* Set a max height to allow scrolling */
    overflow-y: auto; /* Enable vertical scrolling */
    padding: 0; /* Maintain padding */
  }

  .recommendation-ids li {
    margin-bottom: 8px; /* Maintain spacing */
  }

  .recommendation-ids button {
    width: 100%; /* Full width on mobile */
    font-size: 1rem; /* Decrease font size */
    padding: 8px 10px; /* Adjust padding */
  }

  .error-message {
    font-size: 1rem; /* Adjust font size */
  }

  .go-back-button {
    padding: 8px 12px; /* Adjust padding */
    font-size: 1rem; /* Reduce font size */
    margin-bottom: 8px; /* Adjust margin */
    border: none; /* Remove border */
    width: 100%; /* Full width on mobile */
  }
}
