form {
  background: white;
  padding: 20px;
  padding-top: 50px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 550px;
  margin: 0 auto;
  padding-left: 40px;
}


form label {
    display: block;
    font-weight: bold;
    margin-bottom: 10px;
    color: #444;
    padding-bottom: 5px;
}

form input {
    width: 95%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
}

form button {
    width: 90%;
    padding: 10px;
    background-color: #000000;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-bottom: 20px;
}

form button:hover {
    text-decoration: none;
    background: linear-gradient(to right, #fa0366 -40%, #000000 140%);
    -webkit-text-fill-color: white;

    font-weight: 500;
    cursor: pointer;
}

h2 {
    font-size: 1.5rem;
    margin-top: 30px;
    margin-bottom: 10px;
    color: #555;
}

ul {
    list-style-type: none;
    padding: 0;
    width: 100%;
    background: white;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

ul li {
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

ul li:last-child {
    border-bottom: none;
}

ul li a {
    text-decoration: none;
    color: #007bff;
}

ul li a:hover {
    text-decoration: underline;
}

@media (max-width: 600px) {

    form, ul {
        width: 90%;
    }
}


form p {
    font-size: x-small;
    color:#9b9696
}

.tag-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
  width: 90%;
}

.tag {
  background-color: #bdc7ea;
  border-radius: 10px;
  padding: 5px 10px;
  margin-top: 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  height: 30px;
  font-size: smaller;
  cursor: default;
}

.tag button {
  background: none;
  border: none;
  color: #555;
  cursor: pointer;
  font-size: 16px;
  padding: 0;
  margin-left: 8px;
  line-height: 1;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tag button:hover {
  color: #fa0366;
}
