.github-login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #24292e;
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  font-size: 1.2rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.github-login-button:hover {
  background: linear-gradient(to right, #fa0366 -40%, #000000 140%);
  -webkit-text-fill-color: rgb(255, 255, 255);
  cursor: pointer;
}

.github-icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

h3 {
  margin-top: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;

}

.how-container {
  display: flex;
  gap: 4rem;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  padding-left: 50px;
  padding-right: 50px;

}

.image-container {
  max-width: 50%; /* Adjust the size as needed */
  margin-top: 90px;
  max-height: 500px;
  overflow: hidden;
}

.image-container img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 4px;
  box-shadow: #24292e;
}

.image-container img:hover {
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.1);
}

.how-container ul li:hover {
  text-decoration: none;
  background: linear-gradient(to right, #fa0366 -40%, #000000 140%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  cursor: pointer;
}

.how-container ul {
  list-style-type: none;
  padding: 10px;
}

.features-container {
  display: flex;
  gap: 4rem;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  padding-left: 50px;
  padding-right: 50px;
}

.features-container ul li:hover {
  text-decoration: none;
  background: linear-gradient(to right, #fa0366 -40%, #000000 140%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  cursor: pointer;
}

.features-container ul {
  list-style-type: none;
  padding: 10px;
}

.login-container p{
  font-size: 1.2rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.login-container {
  margin-left: 3rem;
}

/* Mobile view adjustments */
@media (max-width: 600px) {
  .github-login-button {
    padding: 0.6rem 1rem; /* Reduce padding for smaller screens */
    font-size: 1rem; /* Decrease font size */
  }

  .github-icon {
    font-size: 1.2rem; /* Decrease icon size */
    margin-right: 0.3rem;
  }

  h3 {
    font-size: 1.2rem; /* Reduce font size */
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }

  .how-container {
    flex-direction: column; /* Stack elements vertically */
    gap: 2rem; /* Reduce gap between elements */
    padding: 20px 20px; /* Decrease padding */
  }

  .image-container {
    max-width: 100%; /* Full width on mobile */
    margin-top: 20px; /* Adjust margin */
    max-height: none; /* Remove max height constraint */
  }

  .features-container {
    flex-direction: column; /* Stack elements vertically */
    gap: 2rem; /* Reduce gap between elements */
    padding: 20px 20px; /* Decrease padding */
  }

  .login-container {
    margin-left: 0; /* Remove left margin for full width */
    padding: 0 10px; /* Add horizontal padding */
  }

  .login-container p {
    font-size: 1rem; /* Reduce font size */
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }
}

.login-buttons {
  display: flex;
  justify-content: left; /* Center buttons horizontally */
  gap: 10px; /* Add spacing between buttons */
}
