@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

html {
  scroll-behavior: smooth;
}

:root {
  --c-b: #090909;
  --c-w: #ffffff;
  --c-g: rgba(0, 0, 0, 0.53);
  /* --f-p: 'Roboto', sans-serif; */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}

.app {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  height: 100%;
  padding: 1.4rem 0 0;
  overflow-x: hidden;
  background-color: var(--c-b);
}