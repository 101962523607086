.navbar {
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: center;
}

.navbar-content {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: left;
  padding: 0 0 2rem 0;
}

.logo img {
  height: 55px; /* Adjust as needed */
}

.nav-links {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.nav-links li a {
  text-decoration: none;
  color: #000000; /* Adjust color as needed */
  font-weight: bold;
}

.nav-links li a:hover {
  text-decoration: none;
  background: linear-gradient(to right, #9181f4 -40%, #ed6c7d 140%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* font-weight: 700; */
  cursor: pointer;
}

.logout-button {
  background-color: #000000;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.logout-button:hover {
  background-color: #5b5859;
}