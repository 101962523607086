/* Ensure body and html take up the full height */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  scroll-behavior: smooth;
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

/* Wrapper to ensure full height with flexbox */
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
}

/* Main content area should flex to take up available space */
.content {
  flex: 1;
  background-color: var(--c-b);
}

/* Footer styles */
.footer {
  background-color: white;
  padding: 20px 0;
  width: 100%;
  position: fixed; /* Make the footer stick to the bottom */
  bottom: 0; /* Align it to the bottom */
  left: 0;
  right: 0;
  z-index: 1000; /* Ensure it appears above other content */
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.footer-content p {
  color: #858585;
  font-size: small;
}

.footer-content a {
  text-decoration: none;
  color: rgb(48, 48, 44);
}

.feedback {
  text-decoration: underline;
  color: #858585;
  font-weight: bold;
}

.footer-content a:hover {
  text-decoration: none;
  background: linear-gradient(to right, #fa0366 -40%, #000000 140%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  cursor: pointer;
}

.social-links {
  display: flex;
  gap: 20px;
}

.social-links a {
  color: #333;
  font-size: 24px;
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: #b1b8b8;
}

@media (max-width: 600px) {
  .footer-content {
    flex-direction: column;
    text-align: center;
  }

  .social-links {
    margin-top: 15px;
  }
}