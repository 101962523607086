.reco-container {
  background-color: #ffffff;
  padding: 20px;
  /* padding-bottom: 70px; */
  width: 100%; /* Full width of the parent */
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box; /* Include padding and border in width */
  /* margin-top: 30px; */
}

.reco-container h2 {
  margin-left: 1rem;
  margin-bottom: 20px;
}

.repo-ul a {
  color: #000000;
}

.repo-ul {
  width: 100%; /* Ensure the ul takes full width */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
  list-style-type: none; /* Remove bullet points */
}

.reco-container li {
  width: 100%; /* Ensure each li takes full width */
  margin-bottom: 15px; /* Optional: spacing between items */
}
.repo-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%; /* Ensures the card takes full width */
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: box-shadow 0.3s ease;
  margin-bottom: 15px; /* Optional: Adds space between cards */
}

.repo-info {
  flex: 1;
}

.repo-card:hover {
  box-shadow: 8px 8px 8px rgba(9, 109, 224, 0.1);
}

.repo-info h3 {
  font-size: 18px;
  margin-bottom: 8px;
}

.repo-info p {
  color: #666;
  margin-bottom: 12px;
}

.repo-details {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
  font-size: small;
}

.repo-details span {
  margin-right: 10px;
  margin-bottom: 5px;
  padding: 5px 10px; /* Keep padding for spacing */
  /* Removed background-color and border-radius */
}

.repo-link {
  margin-top: 10px;
}

.repo-link a {
  color: #333;
  text-decoration: none;
  font-size: 16px;
}

.repo-link a:hover {
  text-decoration: underline;
  background: linear-gradient(to right, #fa0366 -40%, #000000 140%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  cursor: pointer;
}

.topics-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
}

.topic {
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 8px;
  margin-bottom: 8px;
}
