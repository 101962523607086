/* App.css */
html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Inter', sans-serif;
  background-color: #ffffff;
  color: #000000;
  margin: 0;
  padding: 0;
}

.content-container {
  width: 70%;
  margin: 0 auto;
}

.App {
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 40px;
  padding-bottom: 10px;
}

.App-main {
  padding-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-header {
  background-color: #282c34;
  color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 20px;
}

.App-header h1 {
  font-size: 2rem;
  margin: 0;
}

.Input {
  padding-right: 60px;
}

.app-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-top: auto;
  padding: 20px;
}

.form-container {
  width: 100%;
  transition: width 0.3s ease-in-out;
}

.top-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.input-container {
  width: 48%; /* Adjust as needed */
}

.previous-recommendations-container {
  width: 48%; /* Adjust as needed */

}

.recommendations-container {
  width: 100%;
  margin-top: 20px;
}

.recommendations-container:empty {
  display: none;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
}

.loader {
  /* Add your loader styles here */
}

/* Responsive styles */
@media (max-width: 900px) {
  .app-container {
    flex-direction: column;
  }

  .form-container,
  .form-container.with-recommendations,
  .recommendations-container {
    width: 100%;
  }

  .recommendations-container {
    margin-top: 10px;
  }

  .top-row {
    flex-direction: column;
  }

  .input-container,
  .previous-recommendations-container {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media (max-width: 600px) {
  .App-header h1 {
    font-size: 1.5rem;
  }
}

/* Additional styles for the recommendation list */
.repo-ul {
  list-style-type: none;
  padding: 0;
}

.repo-card {
  background-color: #ffffff;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
}

.repo-info h3 {
  margin-top: 0;
}

.repo-info a {
  color: #0366d6;
  text-decoration: none;
}

.repo-info a:hover {
  text-decoration: underline;
}

.topics-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
}

.topic {
  background-color: #e1e4e8;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 8px;
  margin-right: 4px;
  margin-bottom: 4px;
}

.repo-details {
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  color: #586069;
}

.repo-details span {
  margin-right: 16px;
}

.repo-link {
  margin-top: 12px;
}

.repo-link a {
  color: #0366d6;
  text-decoration: none;
}

.repo-link a:hover {
  text-decoration: underline;
}

/* Styles for the previous recommendations list */
.reco-container {
  background-color: #ffffff;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 16px;
  margin-top: 0px;
}

.reco-container h2 {
  margin-top: 0;
}

.recommendation-ids {
  list-style-type: none;
  padding: 0;
}

.recommendation-ids li {
  margin-bottom: 8px;
}

.recommendation-ids button {
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.recommendation-ids button:hover {
  background: linear-gradient(to right, #fa0366 -40%, #000000 140%);
    -webkit-text-fill-color: white;

    font-weight: 500;
    cursor: pointer;
  text-decoration: underline;
}

.error-message {
  color: #dc3545;
  font-weight: bold;
}

/* Mobile view adjustments */
@media (max-width: 600px) {
  body {
    padding: 10px; /* Add some padding to avoid content touching the screen edges */
  }

  .content-container {
    width: 100%; /* Full width on mobile */
    padding: 0 15px; /* Add some horizontal padding */
  }

  .App {
    padding: 20px;
  }

  .App-main {
    flex-direction: column; /* Stack elements vertically */
    padding-top: 20px;
  }

  .App-header {
    padding: 10px;
    margin-bottom: 10px;
  }

  .App-header h1 {
    font-size: 1.2rem; /* Smaller font size for headers */
  }

  .top-row {
    flex-direction: column; /* Stack form and previous recommendations */
    gap: 10px; /* Add space between stacked elements */
  }

  .input-container,
  .previous-recommendations-container {
    width: 100%; /* Full width for inputs */
  }

  .recommendations-container {
    width: 100%;
    margin-top: 10px;
  }

  .reco-container {
    padding: 15px;
  }

  .repo-card {
    padding: 10px;
    margin-bottom: 10px;
  }

  .repo-info h3 {
    font-size: 16px;
  }

  .repo-info p {
    font-size: 14px;
  }

  .repo-details {
    font-size: 12px;
  }

  .repo-link a {
    font-size: 14px;
  }

  .topics-container .topic {
    padding: 4px 8px;
    font-size: 12px;
  }

  .recommendation-ids button {
    padding: 6px 10px;
    font-size: 14px;
  }

  .error-message {
    font-size: 14px;
  }
}
